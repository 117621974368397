import {
  SEARCH_PRODUCTS,
  FETCH_PRODUCTS,
  LOADING,
  LOADER,
  PAGINATE,
  KEYWORD,
  FILTERED_PRODUCTS
} from "./types";
import axios from "axios";
import { userService } from "../_services/user.service";

import { apiBaseUrl } from "../config.example";

const token = userService.getToken();

export const searchProduct = text => dispatch => {
  dispatch({
    type: SEARCH_PRODUCTS,
    payload: text
  });
};

export const searchText = text => dispatch => {
  dispatch({
    type: SEARCH_PRODUCTS,
    payload: text
  });
};

//Filter Value
export const filterProducts = value => dispatch => {
  dispatch({
    type: FILTERED_PRODUCTS,
    payload: value
  });
};


export const fetchPaginationProducts = page => dispatch => {
  console.log("called this fetchPaginationProducts");
  setLoading();
  dispatch({
    type: LOADER,
  });
  var text=localStorage.getItem("searchData");
  text= (text!=="")?text:"none";

  var pdt= localStorage.getItem("pdt");
  pdt=JSON.parse(pdt);

  var searched_Domains=[];
  for(var a in pdt){
    if(pdt[a].type==="External"){
      searched_Domains.push(pdt[a]['domain']);
    }
  }
  const api="/v2/search";
  const searchWords=(text!=="")?text:"none";
  var data={
    page:page,
    searchString:searchWords,
    domains:searched_Domains
  }
  var config = {
    method: 'post',
    url: apiBaseUrl + api,
    headers: { Authorization: `Bearer ${token}` },
    data : data
  };

  axios(config)
    .then(res => {
      console.log("Data:::",res);
      try {
        dispatch({
          type: FETCH_PRODUCTS,
          payload: pdt.concat(res)
        });
      } catch (err) {
        console.log("error", err);
      }
    });

};

export const fetchProducts = text => dispatch => {
  dispatch({
    type: FETCH_PRODUCTS,
    payload: []
  });
  setLoading();
  dispatch({
    type: LOADER,
  });

  const api="/v2/search";
  const searchWords=(text!=="")?text:"none";
  localStorage.setItem("index", 1);
  var data={
    page:1,
    searchString:searchWords,
    domains:[]
  }
  var config = {
    method: 'post',
    url: apiBaseUrl + api,
    headers: { Authorization: `Bearer ${token}` },
    data : data
  };

  axios(config)
    .then(res => {
      try {
        dispatch({
          type: FETCH_PRODUCTS,
          payload: res
        });
      } catch (err) {
        console.log("error", err);
      }
    });
};

export const saveKeyword = text => dispatch => {
  const api = `/search/${text}`;

  axios
    .get(apiBaseUrl + api, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      console.log("Keyword Api called");
      try {
        dispatch({
          type: KEYWORD,
          payload: res
        });
      } catch (err) {
        console.log("Error in Keyword", err);
      }
    });
};

export const paginate = currentPage => dispatch => {
  dispatch({
    type: PAGINATE,
    payload: currentPage
  });
  dispatch(fetchProducts("paginate"));
};

export const setLoading = () => {
  console.log("setting loading");
  return {
    type: LOADING
  };
};
