import * as Locations from "./locations";

import { Redirect, Route, matchPath } from "react-router-dom";

import React from "react";
import { userService } from "./_services/user.service";

const companyLocations = [
  Locations.HomeLocation._path,
  Locations.DashboardLocation._path,

  Locations.GroupListLocation._path,
  Locations.GroupLocation._path,
  Locations.NewGroupLocation._path,

  Locations.ProductListLocation._path,
  Locations.ProductLocation._path,
  Locations.NewProductLocation._path,

  Locations.SubUserListLocation._path,
  Locations.MessagesListLocation._path,
  Locations.NewSubUserLocation._path,
  Locations.SubUserLocation._path,
  Locations.ProductPreviewLocation._path,
  Locations.VouchersListLocation._path,
  Locations.NewBatchLocation._path,
  Locations.BatchListLocation._path,

  Locations.EventLocation._path,
  Locations.EventDetailLocation._path,
  Locations.NewEventLocation._path,
  Locations.ProductHotlineLocation._path,
  Locations.NewProductHotlineLocation._path,
  Locations.EditProductHotlineLocation._path
];

const companySubUserLocations = [
  Locations.HomeLocation._path,
  Locations.DashboardLocation._path,
  Locations.MessagesListLocation._path,
  Locations.ProductLocation._path,
  Locations.ProductListLocation._path,
  Locations.ProductViewLocation._path,
  Locations.ProductPreviewLocation._path,
];

const superAdminLocations = [
  Locations.HomeLocation._path,
  Locations.DashboardLocation._path,
  Locations.VouchersListLocation._path,
  Locations.NewBatchLocation._path,
  Locations.BatchListLocation._path,

  Locations.GroupListLocation._path,
  Locations.GroupLocation._path,
  Locations.NewGroupLocation._path,

  Locations.CategoryListLocation._path,
  Locations.CategoryLocation._path,
  Locations.NewCategoryLocation._path,

  Locations.DoctorListLocation._path,
  Locations.DoctorBranchesChartLocation._path,
  Locations.DoctorLocation._path,
  Locations.NewDoctorLocation._path,
  Locations.ProductPreviewLocation._path,

  Locations.CompanyListLocation._path,
  Locations.CompanyLocation._path,
  Locations.NewCompanyLocation._path,

  Locations.RoleListLocation._path,
  Locations.RoleLocation._path,
  Locations.NewRoleLocation._path,
  Locations.SourcesListLocation._path,
  Locations.RequestedSourceListLocation._path,

  Locations.ProductUserInteractionListLocation._path,
  Locations.ProductListLocation._path,
  Locations.ProductLocation._path,
  Locations.NewProductLocation._path,

  Locations.EventLocation._path,
  Locations.EventDetailLocation._path,
  Locations.NewEventLocation._path,

  Locations.DoctorNetworkLocation._path,
  Locations.NewDoctorNetworkLocation._path,
  Locations.EditDoctorNetworkLocation._path
];

const doctorLocations = [
  Locations.DoctorHomeLocation._path,
  Locations.DoctorProductLocation._path,
  Locations.DoctorArticleLocation._path
];

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let authorized = false;
      let targetUrl = props.location.pathname;

      if (!userService.isLoggedIn()) {
        return redirectToLogin(props);
      } else if (userService.isDoctor()) {
        console.log("isdoctor");
        authorized = doctorLocations.some(urlStructure =>
          isPathMatched(urlStructure, targetUrl)
        );
      } else if (userService.isSuperAdmin()) {
        authorized = superAdminLocations.some(urlStructure =>
          isPathMatched(urlStructure, targetUrl)
        );
      } else if (userService.isCompanyAdmin()) {
        authorized = companyLocations.some(urlStructure =>
          isPathMatched(urlStructure, targetUrl)
        );
      } else {
        authorized = companySubUserLocations.some(urlStructure =>
          isPathMatched(urlStructure, targetUrl)
        );
      }

      if (authorized) {
        return <Component {...props} />;
      } else {
        return redirectToHome(props);
      }
    }}
  />
);

function redirectToHome(props) {
  return (
    <Redirect
      to={{
        pathname: Locations.HomeLocation.path,
        state: { from: props.location }
      }}
    />
  );
}

function redirectToLogin(props) {
  return (
    <Redirect
      to={{
        pathname: Locations.LoginLocation.path,
        state: { from: props.location }
      }}
    />
  );
}

function isPathMatched(urlStructure, targetUrl) {
  let result = matchPath(targetUrl, { path: urlStructure, exact: true });
  // console.log(urlStructure, targetUrl, result)
  if (result && result.params && result.params.id === "new") {
    return false;
  }
  return result ? true : false;
}

export default PrivateRoute;
