import { apiBaseUrl, countryCode } from "../config.example";
import { cogoToastHelper } from "./cogoToastHelper";
import { userService } from "../_services/user.service";
import i18next from "i18next";
import urlJoin from "proper-url-join";
import axios from "axios";

export function setupInterceptor() {
  let isAbsoluteURLRegex = /^(?:\w+:)\/\//;
  var language = i18next.language;

  axios.interceptors.request.use(
    (config) => {
      if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = urlJoin(apiBaseUrl, config.url);
      }

      config.headers.Authorization = "Bearer " + userService.getToken();
      config.headers["X-Country-Code"] = countryCode;
      config.headers["X-Language"] = language;
      return config;
    },
    (error) => {
      cogoToastHelper.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status !== 200) {
        const error =
          (response.data && response.data.message) || response.statusText;
        console.log(error);
        return Promise.reject(error);
      }

      // If response is an ApiResponse, return it as is
      if (isApiResponse(response.data)) {
        return response.data;
      }

      if (response.data.data !== undefined && response.data.data !== null) {
        return response.data.data;
      } else if (response.data.data === null) {
        return response.data.message;
      } else {
        return response.data;
      }
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        userService.logout();
      }

      let errorMessage = "";
      if (error.response) {
        errorMessage =
          (error.response.data && error.response.data.message) ||
          error.response.statusText;
      } else {
      }
      if (Array.isArray(errorMessage)) {
        errorMessage = processErrorMessages(errorMessage);
      }

      return Promise.reject(errorMessage);
    }
  );
}

function processErrorMessages(messages) {
  return messages.map((message) => message.description + " ");
}

// Function to check if the response matches the ApiResponse format
function isApiResponse(response) {
  return (
    response &&
    typeof response === "object" &&
    "httpStatusCode" in response &&
    ("message" in response || "error" in response || "data" in response)
  );
}
