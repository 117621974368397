import "./docregister.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { apiBaseUrl } from "../../../config.example";
import { userService } from "../../../_services/user.service";
import { sourcesService } from "../../../_services/sources.service";
import { cogoToastHelper } from "../../../_helpers/cogoToastHelper";
import { voucherServices } from "../../../_services/voucher.service";
import { countryCode, stripePaymentActivated } from "../../../config.example";
import { doctornetworkService } from "../../../_services/doctornetwork.service";
import React from "react";
import axios from "axios";
import i18next from "i18next";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";

export default class DocRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      professions: [],
      professionalAreaList: [],
      selectedAreas: [],
      doctorNetworks: [],
      sponsorData: {},
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      userName: "",
      employer: "",
      gln: "",
      doctorNetworkId: 999999,
      message: "",
      reginfo: false,
      regSuccess: false,
      gnlerror: false,
      sponsorModal: false,
      profilePicture: "",
      academicTitle: "",
    };
  }

  componentDidMount() {
    this.loadDropdownData();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAreasChange = (selectedOption) => {
    this.setState({ selectedAreas: selectedOption });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { password, confirmPassword, academicTitle, professions } =
      this.state;
    if (password !== confirmPassword) {
      return cogoToastHelper.info(i18next.t("passwordDoesNotMatch"));
    }
    if (!academicTitle && professions.length > 0) {
      this.setState({ academicTitle: professions[0].professionalTitle }, () => {
        this.handleRegister();
      });
    } else {
      this.handleRegister();
    }
  };

  handleRegister = async () => {
    const postData = this.constructPostData();
    if (countryCode === "de") {
      userService.registerDoctor(
        postData,
        this.handleRegisterSuccess,
        this.handleRegisterFailure
      );
    } else {
      const glnValid = await this.checkGLN(postData.get("GLN"));
      if (glnValid) {
        userService.registerDoctor(
          postData,
          this.handleRegisterSuccess,
          this.handleRegisterFailure
        );
      }
    }
  };

  handleRegisterSuccess = (message) => {
    this.setState({ loading: false, message, reginfo: true, regSuccess: true });
    this.sponsor(this.state.voucher);
  };

  handleRegisterFailure = (error) => {
    this.setState({ loading: false });
    cogoToastHelper.error(error || i18next.t("registrationFailed"));
  };

  constructPostData = () => {
    const {
      academicTitle,
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      userName,
      employer,
      gln,
      doctorNetworkId,
      selectedAreas,
      voucher,
      profileFile,
    } = this.state;

    const specialistTitle = selectedAreas.map((area) => area.label).join(", ");

    const formData = new FormData();
    formData.append("Email", email);
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);
    formData.append("Password", password);
    formData.append("ConfirmPassword", confirmPassword);
    formData.append("UserName", userName);
    formData.append("SpecialistTitle", specialistTitle);
    formData.append("Employer", employer);
    formData.append("GLN", gln);
    formData.append("DoctorNetworkId", doctorNetworkId);
    formData.append("Voucher", voucher);
    formData.append("AcademicTitle", academicTitle);

    if (profileFile) {
      formData.append("File", profileFile);
    }

    return formData;
  };

  onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (!["image/png", "image/jpeg", "image/jpg"].includes(fileType)) {
        alert(i18next.t("validImageFormat"));
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      this.setState({ profilePicture: imageUrl, profileFile: file });
    }
  };

  checkGLN = async (gln) => {
    this.setState({ loading: true });

    try {
      const isValid = await userService.checkGLN(gln);

      if (isValid) {
        this.setState({ loading: false });
        return isValid;
      } else {
        this.setState({ gnlerror: true, loading: false });
        cogoToastHelper.error(i18next.t("GLNnotAllowed"));
        return false;
      }
    } catch (error) {
      this.setState({ gnlerror: true, loading: false });

      let errorMessage = i18next.t("GLNnotFound");
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      cogoToastHelper.error(errorMessage);
      return false;
    }
  };

  sponsor = async (voucher) => {
    try {
      const response = await voucherServices.Sponsor(voucher);
      this.setState({ sponsorData: response, sponsorModal: true });
    } catch (error) {
      console.error("Sponsor request failed", error);
    }
  };

  loadDropdownData = () => {
    this.setState({ loading: true });
    Promise.all([
      sourcesService.professions(),
      sourcesService.professionalArea(),
      doctornetworkService.getAllDoctorNetworks(),
    ])
      .then(([professions, professionalAreas, doctorNetworks]) => {
        const professionalAreaList = professionalAreas.map((area) => ({
          value: area.id,
          label: area.area,
        }));
        this.setState({
          professions,
          professionalAreaList,
          doctorNetworks,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error(i18next.t("dropdownLoadFail"), error);
      });
  };

  render() {
    return (
      <div
        className="app align-items-center jumbotron d-flex align-items-center"
        style={{
          backgroundImage: "url(/assets/img/doctors2.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div class="body1 container register">
          <div class="row">
            <div class="col-md-3 register-left">
              <img src="/assets/img/logo1.png" alt="" />
              <h3>{i18next.t("welcome")}</h3>
              <p>{i18next.t("welcomeNote")}</p>
              <Button
                style={{ backgroundColor: "#2d2762", color: "#fff" }}
                onClick={() => (window.location.href = "./login")}
              >
                {i18next.t("backtoLogin")}
              </Button>

              <br />
              <br />
              <br />
              <p>
                {i18next.t("internet")}:{" "}
                <a href="https://www.digital-doctor-house.com">
                  www.digital-doctor-house.com
                </a>
                <br />
                {i18next.t("phone")}:{" "}
                {countryCode === "de"
                  ? "+49 661 965 944 99"
                  : "+41 41 501 49 73"}
                <br />
                {i18next.t("emailLabel")}:{" "}
                <a href="mailto:support@digital-doctor-house.com">
                  support@digital-doctor-house.com
                </a>
              </p>
              <br />
            </div>
            <div className="col-md-9 register-right">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h3 className="register-heading">{i18next.t("register")}</h3>
                  <form
                    className="row register-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="col-12" style={{ textAlign: "center" }}>
                      {this.state.message !== "" && (
                        <small>
                          {i18next.t(this.state.message)}
                          <br />
                          <br />
                        </small>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div
                        className="profilePic"
                        style={{
                          width: 240,
                          height: 240,
                          borderRadius: "50%",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        {this.state.profilePicture ? (
                          <img
                            src={
                              this.state.profilePicture ||
                              "/assets/img/placeholder.png"
                            }
                            alt={i18next.t("profilePicture")}
                            style={{
                              width: "110%",
                              height: "110%",
                              objectFit: "cover",
                              backgroundColor: "#f0f0f0",
                            }}
                          />
                        ) : null}
                      </div>
                      <br />
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={this.onFileChange}
                        className="fileUpload"
                        style={{ width: "100%" }}
                        ref={(input) => (this.inputElement = input)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          placeholder={i18next.t("firstname")}
                          value={this.state.firstName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          placeholder={i18next.t("lastname")}
                          value={this.state.lastName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="userName"
                          placeholder={i18next.t("username")}
                          value={this.state.userName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder={i18next.t("password")}
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          placeholder={i18next.t("confirmpassword")}
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder={i18next.t("youtemail")}
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="employer"
                          placeholder={i18next.t("youremployer")}
                          value={this.state.employer}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>{i18next.t("speciality")}</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={makeAnimated()}
                          isMulti="true"
                          value={this.state.selectedAreas}
                          options={this.state.professionalAreaList}
                          onChange={this.handleAreasChange}
                        />
                      </div>

                      <div className="form-group">
                        <select
                          type="text"
                          name="academicTitle"
                          id="academicTitle"
                          placeholder={i18next.t("academictitle")}
                          value={this.state.academicTitle}
                          onChange={this.handleChange}
                        >
                          {this.state.professions.map((title) => (
                            <option
                              key={title.id}
                              value={title.professionalTitle}
                            >
                              {title.professionalTitle}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="gln"
                          required
                          placeholder={
                            countryCode == "de"
                              ? i18next.t("lanrNumberLabel")
                              : i18next.t("glnNumberLabel")
                          }
                          value={this.state.gln}
                          onChange={this.handleChange}
                        />
                      </div>

                      {countryCode == "de" &&
                        this.state.doctorNetworks.length > 0 && (
                          <div className="form-group">
                            <select
                              type="text"
                              name="doctorNetworkId"
                              id="doctorNetworkId"
                              placeholder={i18next.t("Doctor network")}
                              value={this.state.doctorNetworkId}
                              onChange={this.handleChange}
                            >
                              <option value={0}>
                                {i18next.t("No doctor network selected")}
                              </option>
                              {this.state.doctorNetworks.map((network) => (
                                <option value={network.id}>
                                  {network.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="voucher"
                          placeholder={i18next.t("voucher")}
                          value={this.state.voucher}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="info-circle">
                        <label>
                          <i className="fa fa-info-circle" aria-hidden="true" />{" "}
                          {i18next.t("regInfo")}
                        </label>
                        <div className="overlay">
                          <span style={{ color: "black", fontSize: "small" }}>
                            {i18next.t("password")}:
                          </span>
                          <span style={{ color: "red", fontSize: "small" }}>
                            {i18next.t("passwordInfo")}
                          </span>
                          <br></br>
                          {/* BAN NUMMBER */}
                          <span style={{ color: "black", fontSize: "small" }}>
                            {countryCode === "de"
                              ? i18next.t("LANRNumberLabel")
                              : i18next.t("glnNumberLabel")}
                          </span>

                          <span style={{ color: "red", fontSize: "small" }}>
                            {countryCode === "de"
                              ? i18next.t("LANRauthentication")
                              : i18next.t("GLNauthentication")}
                            {/* " For authenticating the doctors legality, We ask you for your GNL nummer to validate your identity" */}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <strong>
                          {/*only show trial note in switzerland*/}
                          {countryCode === "ch" ? i18next.t("trialNote") : ""}
                        </strong>
                      </div>
                      {this.state.loading == false && (
                        <Button
                          type="submit"
                          style={{ backgroundColor: "#2d2762", color: "#fff" }}
                        >
                          {i18next.t("register")}
                        </Button>
                      )}

                      {this.state.loading == true && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner color="success" />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.gnlerror} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.setState({ gnlerror: false })}>
            {countryCode === "de"
              ? i18next.t("LANRvalidation")
              : i18next.t("GLNvalidation")}
          </ModalHeader>
          <ModalBody>
            {countryCode === "de"
              ? i18next.t("LANRnotValid")
              : i18next.t("GLNnotValid")}
            <br />
            {}
            {i18next.t("phone")}:{" "}
            {countryCode == "de" ? "+49 661 965 944 99" : "+41 41 501 49 73"}{" "}
            <br />
            {i18next.t("emailLabel")}:{" "}
            <a href="mailto:support@digital-doctor-house.com">
              support@digital-doctor-house.com
            </a>
            <br />
            <br />
            <br />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.reginfo} className="modal-content modal-lg">
          <ModalHeader
            toggle={() => this.setState({ reginfo: false, regSuccess: false })}
          >
            {i18next.t("registrationInfo")}
          </ModalHeader>
          <ModalBody>
            {stripePaymentActivated === "true" ? (
              <p>{i18next.t(this.state.message)}</p>
            ) : this.state.message && this.state.regSuccess ? (
              <div>
                <p>{i18next.t(this.state.message)}</p>
                <p>{i18next.t("verifyEmail")}</p>
              </div>
            ) : (
              <p>{i18next.t(this.state.message)}</p>
            )}
          </ModalBody>
          <ModalFooter>
            {this.state.message && this.state.regSuccess ? (
              <Button
                color="success"
                onClick={() => (window.location.href = "/login")}
              >
                {i18next.t("close")}
              </Button>
            ) : (
              <Button
                color="danger"
                onClick={() => this.setState({ reginfo: false })}
              >
                {i18next.t("close")}
              </Button>
            )}
            {stripePaymentActivated === "true" ? (
              <Button
                color="success"
                onClick={() => (window.location.href = "/Subscription")}
              >
                {i18next.t("subscribe")}
              </Button>
            ) : (
              ""
            )}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.sponsorModal}
          className="modal-content modal-lg"
        >
          <ModalHeader
            toggle={() => this.setState({ sponsorModal: false })}
          ></ModalHeader>
          <ModalBody>
            <center>
              {this.state.sponsorData &&
                this.state.sponsorData.companyName != undefined && (
                  <img src={this.state.sponsorData.logo} />
                )}
              <p>
                {i18next.t("sponsoredBy")}{" "}
                {this.state.sponsorData &&
                this.state.sponsorData.companyName !== undefined
                  ? this.state.sponsorData.companyName
                  : ""}
              </p>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => this.setState({ sponsorModal: false })}
            >
              {i18next.t("close")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
