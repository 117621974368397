import axios from "axios";
import { API } from "../urls";
import { userService } from "../_services/user.service";

export const messagesService = {
  getConversation,
  getMessage,
  sendMessage,
  createMessage,
  getChatroom,
  sendChat,
  support,
  getChatroomMembers,
  deleteMember,
  getChatroom1,
  searchFeedback,
};

const token = userService.getToken();

function getConversation(id) {
  let url = API.conversationLocation.toUrl({ id: id });
  return axios.get(url);
}

function getMessage(id){
	let url = API.messagesLocation.toUrl({ id: id });
  return axios.get(url);
}

function sendMessage(data){
	let url = API.sendMessageLocation.toUrl();
  return axios.post(url,data);
}
function createMessage(data){
	let url = API.createMessageLocation.toUrl();
  return axios.post(url,data);
}

function support(data){
	let url = API.supportMessageLocation.toUrl();
  return axios.post(url,data);
}

function getChatroom(id){
	let url = API.chatroomLocation.toUrl({ id: id });
  return axios.get(url);
}

function getChatroom1(id,receiver){
	let url = API.chatroomLocations.toUrl({ sender: id, receiver:receiver });
  return axios.get(url);
}

function getChatroomMembers(id){
	let url = API.chatroomMembersLocation.toUrl({ id: id });
  return axios.get(url);
}

function deleteMember(id){
	let url = API.chatroomLocation.toUrl({ id: id });
  return axios.delete(url);
}

function sendChat(data){
  let url = API.chatroomMessageLocation.toUrl();
  console.log("sendChat::",data);
  return axios.post(url,data);
}

function searchFeedback(userId,searchedWord){
  let url = API.searchFeedbackLocation.toUrl({userId: userId, searchedWord: searchedWord});
  return axios.get(url);
}
