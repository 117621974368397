import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { alertActions } from '../_actions/alert.actions';


// variable which will point to react-router history
let globalHistory = null;

// component which we will mount on top of the app
class Spy extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      this.props.dispatch(alertActions.clear());
    });
    globalHistory = history;
  }

  componentWillReceiveProps(nextProps) {
    globalHistory = nextProps.history;
  }

  render() {
    return null;
  }
}




export { globalHistory as history }
const ConnectedGlobalHistory = withRouter(Spy);

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

export const GlobalHistory = connect(mapStateToProps)(ConnectedGlobalHistory);
