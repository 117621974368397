import { API } from "../urls";
import axios from "axios";

export const eventService = {
  getEvents,
  getAllEvents,
  getEvent,
  setEvent,
  updateEvent,
  searchEvent,
  getEventsStartingFromCurrentDate
};

function getEvents(year, month){
  let url = API.getEvents.toUrl({year, month});
  return axios.get(url);
}

function getEventsStartingFromCurrentDate(){
  let url = API.getEventsStartingFromCurrentDate.toUrl();
  return axios.get(url);
}

function getAllEvents(){
  let url = API.getAllEvents.toUrl();
  return axios.get(url);
}

function getEvent(id){
  let url = API.getEvent.toUrl({ id: id });
  return axios.get(url);
}

function setEvent(data){
  let url = API.setEvent.toUrl();
  return axios.post(url, data);
}

function updateEvent(data){
  let url = API.updateEvent.toUrl();
  return axios.post(url, data);
}

function searchEvent(searchString){
  let url = API.searchEvent.toUrl({searchString});
  return axios.get(url);
}
