import {
  PRODUCT_DETAILS
} from "./types";
import axios from "axios";
import { store } from "../_helpers/store";
import { userService } from "../_services/user.service";
import { apiBaseUrl } from "../config.example";

export function detailsProduct(id) {
  const token = userService.getToken();
  const api = `/product/${id}`;

  axios
    .get(apiBaseUrl + api, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      try {
        store.dispatch({
          type: PRODUCT_DETAILS,
          payload: res
        });
      } catch (err) {
        console.log("error", err);
      }
    });
}
