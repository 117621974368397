import { API } from "../urls";
import axios from "axios";

export const favouriteService = {
  getFavourites,
  setFavouriteComment,
  setFavouriteArticle,
  removeFavouriteArticle,
  removeFavouriteSource,
};

async function getFavourites() {
  const res = await axios.get(API.getFavourites.toUrl());
  return res.data;
}

async function setFavouriteComment(data) {
  const res = await axios.post(API.setFavouriteComment.toUrl(), data);
  return res.data;
}

async function setFavouriteArticle(data) {
  const res = await axios.post(API.setFavouriteArticle.toUrl(), data);
  return res.data;
}

async function removeFavouriteArticle(data) {
  const res = await axios.post(API.removeFavouriteArticle.toUrl(), data);
  return res.data;
}

async function removeFavouriteSource(domain) {
  const res = await axios.get(API.removeFavouriteSource.toUrl({ domain }));
  return res.data;
}