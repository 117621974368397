import * as Yup from "yup";

import Location from "react-app-location";

export const API = {
  resetLocation: new Location("account/passwordreset"),
  loginLocation: new Location("account/signin"),
  recoveryLocation: new Location("account/recovery/:id",{ id: Yup.string() }),
  statsLocation: new Location("account/stats"),

  companyBatchLocation: new Location("vouchers/batches/:id",{ id: Yup.string() }),
  companyVouchersLocation: new Location("vouchers/company/:id",{ id: Yup.string() }),
  companySponsor: new Location("vouchers/sponsor/:id",{ id: Yup.string() }),
  companyNewBatchLocation: new Location("vouchers"),
  companySponsor1: new Location("vouchers/sponsor1",),

  groupsLocation: new Location("groups"),
  groupLocation: new Location("groups/:id", { id: Yup.string() }),
  authorizedGroupRolesLocation: new Location("groups/roles"),
  groupRolesLocation: new Location("groups/:id/roles", { id: Yup.string() }),
  groupRoleAssignLocation: new Location("/groups/:id/roles/assign", {
    id: Yup.string()
  }),

  groupRoleDeleteLocation: new Location("/groups/:id", {
    id: Yup.string()
  }),

  categoriesLocation: new Location("categories"),
  categoryLocation: new Location("categories/:id", { id: Yup.string() }),
  conversationLocation: new Location("messages/conversations/:id", { id: Yup.string() }),
  messagesLocation: new Location("messages/:id", { id: Yup.string() }),

  rolesLocation: new Location("roles"),
  rolesCreateLocation: new Location("roles/create"),
  roleLocation: new Location("roles/:id", { id: Yup.string() }),
  roleDeleteLocation: new Location("roles/:name", { id: Yup.string() }),

  productsLocation: new Location("product"),
  productLocation: new Location("product/:id", { id: Yup.string() }),
  productLinksLocation: new Location("product/links"),
  productLinkListLocationLang: new Location("product/links/:id/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productLinkListLocation: new Location("product/links/:id", { id: Yup.string() }),
  productReviewLocation: new Location("product/:id/reviews", {
    id: Yup.string()
  }),
  productDeleteFile: new Location("product/:id/file/:type", { id: Yup.string(), type: Yup.string() }),
  productDeleteVideo: new Location("product/:id/video", { id: Yup.string() }),
  productImageLocation: new Location("product/:id/image", { id: Yup.string() }),
  productImageLocationLang: new Location("product/:id/image/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productFileLocation: new Location("product/:id/file", { id: Yup.string() }),
  productFileLocationLang: new Location("product/:id/file/:langCode", { id: Yup.string(), langCode: Yup.string() }),

  productLiteratureLinks: new Location("product/literaturelinks", { id: Yup.string() }),
  productLiteratureLinksCreate: new Location("product/literaturelinks/:id", { id: Yup.string() }),
  productLiteratureLinksCreateLang: new Location("product/literaturelinks/:id/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productLiteratureLinksUpdate: new Location("product/literaturelinks/:id", { id: Yup.string() }),
  productInfoFilesLocationLang: new Location("product/infofiles/:id/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productInfoFilesLocation: new Location("product/infofiles/:id", { id: Yup.string() }),

  productPatInformationLocationLang: new Location("product/:id/patientinfo/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productPatInformationLocation: new Location("product/:id/patientinfo", { id: Yup.string() }),
  productPacksLocation: new Location("product/packs"),
  productPacksGetLocation: new Location("product/packs/:id", { id: Yup.string() }),
  productPacksGetLocationLang: new Location("product/packs/:id/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productPacksUpdateLocation: new Location("product/packs/:id", { id: Yup.string() }),
  productVideoLocationLang: new Location("product/:id/video/:langCode", { id: Yup.string(), langCode: Yup.string() }),
  productVideoLocation: new Location("product/:id/video", { id: Yup.string() }),
  productUpdateLocation: new Location("product/update"),
  productUpdateCompanyLocation: new Location("product/updatecompany"),
  updateProductFileLocation: new Location("product/file/update/:id",{ id: Yup.string() }),
  updatePatientInfoFile: new Location("product/patientinfo/:id",{ id: Yup.string() }),
  updatePInformationLocation: new Location("product/information/:id",{ id: Yup.string() }),
  updateLiteratureProductLocation: new Location("product/literature/:id",{ id: Yup.string() }),
  productCreateLocation: new Location("product/create"),

  productReviewCreateLocation: new Location("product/:id/reviews", {
    id: Yup.string()
  }),
  productReviewCommentCreateLocation: new Location(
    "product/:id/reviews/:reviewId/comments",
    { id: Yup.string(), reviewId: Yup.string() }
  ),
  tracker: new Location("search/diabetes?url=:id", { id: Yup.string()}),

  doctorsLocation: new Location("/doctor"),
  doctorUserLocation: new Location("/DoctorUser"),
  getDoctorUserLocation: new Location("/DoctorUser/GetDoctorUserByUserId/:userId", { userId: Yup.string() }),
  doctorBranchesChartLocation: new Location("/doctor/doctorBranchesChart"),
  chatroomDoctorsLocation : new Location("/doctor/chatroom"),
  chatroomDoctorsSearchLocation : new Location("/doctor/chatroom/:id", { id: Yup.string() }),
  professionsLocation: new Location("/DoctorUser/professions"),
  professionalAreaLocation: new Location("/DoctorUser/ProfessionalAreas"),
  doctorsStatusChangeLocation: new Location("/doctor/activate/:id", { id: Yup.string() }),
  doctorsDeleteLocation: new Location("/DoctorUser/Delete/:id", { id: Yup.string() }),
  newDoctorLocation: new Location("/Doctor/register"),
  updateDoctorLocation: new Location("/Doctor/update"),
  checkValidityLocation: new Location("/DoctorUser/checkValidity/:id", { id: Yup.string() }),
  cultureLocation: new Location("/Doctor/culture/:culture", { culture: Yup.string() }),
  inviteExternalLocation: new Location("/Doctor/inviteExternal/:email/:userID", { email: Yup.string(), userID: Yup.string() }),

  subscribe: new Location("/search/subscribe"),
  getComments: new Location("/search/subscriptioncomment"),
  subscriptioncomment: new Location("/search/subscriptioncomment"),
  setFavouriteComment: new Location("/search/SetFavouriteComment"),
  setFavouriteArticle: new Location("/search/SetFavouriteArticle"),
  removeFavouriteArticle: new Location("/search/RemoveFavouriteArticle"),
  removeFavouriteSource: new Location("/search/RemoveFavouriteSource/:domain", { domain: Yup.string() }),
  sourcesLocation: new Location("/search/sources"),
  suscriptionsLocation  : new Location("/search/subscriptions"),
  approveSourceLocation: new Location("/search/approve/:id", { id: Yup.string() }),
  unsubscribeLocation: new Location("/search/unsubscribe"),
  getFavourites: new Location("/search/GetFavourites"),
  swissPedDoseDetailsLocation: new Location("/product/GetSwissPedDoseDetails/:id", {id: Yup.string()}),

  updateSourceLocation: new Location("/search/updateSource/:id", { id: Yup.string() }),

  companiesLocation: new Location("/company"),
  companyLocation: new Location("/company/:id", { id: Yup.string() }),
  companyStatusLocation: new Location("/company/:id/status", { id: Yup.string() }),
  newCompanyLocation: new Location("/Company/register"),

  companySubUsersLocation: new Location("/companysub"),
  subUserLocation: new Location("/companysub/:id", { id: Yup.string() }),
  newCompanySubUserLocation: new Location("/companysub/register"),

  getUserProductPermissionsLocation : new Location("/product/permissionslist/:id", { id: Yup.string() }),
  publishProduct: new Location("/product/:id/protect", { id: Yup.string() }),
  productStatus: new Location("/product/:id/status", { id: Yup.string() }),
  updateCompanySubUser: new Location("/companysub/:id", { id: Yup.string() }),
  updateCompanyUser: new Location("/company/:id", { id: Yup.string() }),
  companyPasswordResetUser: new Location("/company/:id/passwordreset", { id: Yup.string() }),
  assignProduct: new Location("/product/assign/:id", { id: Yup.string() }),
  imageUploadLocation: new Location("/product/1/files/upload"),
  sendMessageLocation: new Location("/messages"),
  createMessageLocation: new Location("/messages/conversations"),
  supportMessageLocation: new Location("/messages/support"),
  getMessages: new Location("/messages/:id", { id: Yup.string() }),
  chatroomLocation: new Location("/messages/chatroom/:id", { id: Yup.string() }),
  chatroomLocations: new Location("/messages/chatroom/:sender/:receiver", { sender: Yup.string(),receiver: Yup.string() }),
  chatroomMembersLocation: new Location("/messages/chatroom/members/:id", { id: Yup.string() }),
  chatroomMessagesLocation:new Location("/messages/chats/:id", { id: Yup.string() }),
  chatroomMessageLocation: new Location("/messages/chatroom"),
  searchFeedbackLocation: new Location("/messages/searchFeedback/:userId/:searchedWord",{ userId: Yup.string(),searchedWord: Yup.string() }),


  /**
   * Chatroom
   */
  chatGroupLocation: new Location("/chatroom/chatgroup"),
  chatGroupInvitingLocation: new Location("/chatroom/docInvite"),
  chatGroupInviteLocation: new Location("/chatroom/Invitations/:id/:option", { id: Yup.string(),option: Yup.string() }),
  chatGroupUpdateLocation:new Location("/chatroom/chatgroup/:id", { id: Yup.string() }),
  updateChatGroupForAlertingLocation: new Location("/chatroom/updateChatGroupForAlerting/:groupId/:lastSender", { groupId: Yup.string(),lastSender: Yup.string() }),
  createGroupAccessLocation: new Location("/chatroom/createGroupAccess/:groupId/:userId",{ groupId: Yup.string(), userId: Yup.string() }),
  getLastGroupAccessLocation: new Location("/chatroom/getLastGroupAccess/:groupId/:userId",{ groupId: Yup.string(), userId: Yup.string() }),
  getLastGroupAccessForAllLocation: new Location("/chatroom/getLastGroupAccessForAll"),
  getLastDoctorAccessForAllLocation: new Location("/chatroom/getLastDoctorAccessForAll"),
  pushNotificationLocation: new Location("/chatroom/pushNotification/:message/:userId/:groupId", { message: Yup.string(), userId: Yup.string(), groupId: Yup.string() }),
  /**

  /**
  * Assignment & permissions
  */
  updateAssignedPermissions: new Location("/product/permissions"),

  /**
  * Events
  */
  getEvents: new Location("/event/getEvents/:year/:month", {year: Yup.string(), month: Yup.string()}),
  getEventsStartingFromCurrentDate: new Location("/event/getEvents/startingFromCurrentDate"),
  getAllEvents: new Location("/event/getAllEvents"),
  getEvent: new Location("/event/getEvent/:id", {id: Yup.string()}),
  setEvent: new Location("/event/setEvent"),
  updateEvent: new Location("/event/updateEvent"),
  searchEvent: new Location("/event/searchEvent/:searchString" , {searchString: Yup.string()}),

  /**
   * Doctor Networks
   */
  getDoctorNetworks: new Location("/DoctorNetwork/getDoctorNetworks"),
  getDoctorNetwork: new Location("/DoctorNetwork/getDoctorNetwork/:id", {id: Yup.string()}),
  addDoctorNetwork: new Location("/DoctorNetwork/addDoctorNetwork"),
  updateDoctorNetwork: new Location("/DoctorNetwork/updateDoctorNetwork"),

  /**
   * Product-Hotline
   */
  getAllActiveProducts: new Location("product/getAllActiveProducts"),
  addProductHotline: new Location("product/createProductHotline"),
  getProductHotline: new Location("product/getProductHotline/:id", {id: Yup.string()}),
  getAllProductHotlinesByCompany: new Location("product/getAllProductHotlinesByCompany"),
  deleteProductHotline: new Location("product/deleteProductHotline/:id", {id: Yup.string()}),
  updateProductHotline: new Location("product/updateProductHotline"),
  getAllCompanyProductsByCompanyId: new Location("product/getAllCompanyProductsByCompanyById/:id", {id: Yup.string()}),

  /**
   * Tracking
   */
  setUserInteractionTrackingLocation: new Location("TrackedUserInteraction/SetTrackedUserInteraction"),
  getTrackedUserInteractionsByUserIdLocation: new Location ("TrackedUserInteraction/GetTrackedUserInteractionsByUserId"),
  getTrackedUserInteractionsByProductIdLocation: new Location ("TrackedUserInteraction/GetTrackedUserInteractionsByProductId"),
  getAllTrackedUserInteractionsFromTypeLocation: new Location ("TrackedUserInteraction/GetAllTrackedUserInteractionsFromType"),
  getAllTrackedUserInteractionsLocation: new Location ("TrackedUserInteraction/GetAllTrackedUserInteractions")
};

export const API_V2 = {
  checkGLNAPI: new Location("v2/doctor/CheckGln"),
  registerDoctorAPI: new Location("v2/doctor/register"),
  signInAPI: new Location("v2/doctor/signin"),
  resendVerificationEmailAPI: new Location("v2/account/resend-verification-email"),
  verifyEmailAPI: new Location("v2/account/verify-email"),
  sourcesLocation: new Location("v2/search/sources")
};