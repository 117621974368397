import cogoToast from "cogo-toast";

export const cogoToastHelper = {
  success,
  info,
  loading,
  warn,
  error
};

function onClick(hide) {
  hide();
}

function success(message, options) {
  cogoToast.success(message, { ...options, onClick: onClick });
}

function info(message, options) {
  cogoToast.info(message, { ...options, onClick: onClick });
}

function loading(message, options) {
  cogoToast.loading(message, { ...options, onClick: onClick });
}

function warn(message, options) {
  cogoToast.warn(message, { ...options, onClick: onClick });
}

function error(message, options) {
  cogoToast.error(message, { ...options, onClick: onClick });
}
