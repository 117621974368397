import {
  SEARCH_PRODUCTS,
  FETCH_PRODUCTS,
  LOADING,
  LOADER,
  PAGINATE,
  PRODUCT_DETAILS,
  USER_PROFILE,
  KEYWORD,
  FILTERED_PRODUCTS
} from "../_actions/types";

const initialState = {
  text: "",
  products: [],
  loading: false,
  loader:true,
  product: [],
  profile: [],
  filter: [],
  pagination: {
    totalRecords: 0,
    totalPages: 0,
    pageLimit: 4,
    currentPage: 1,
    startIndex: 1,
    endIndex: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTERED_PRODUCTS:
      console.log(
        "action -- Filtered Pro",
        action,
        "state -- Filtered Pro ",
        state
      );
      return {
        ...state,
        filter: action.payload
      };

    case SEARCH_PRODUCTS:
      return {
        ...state,
        text: action.payload,
        loading: false,
      };

    case PAGINATE:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload }
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
        loader:false,
        totalRecords: action.payload.length
      };

    case LOADING:
      return {
        ...state,
        loading: true
      };
    case LOADER:
      return {
        ...state,
        loader: true
      };

    case PRODUCT_DETAILS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        totalRecords: action.payload.length
      };

    case USER_PROFILE:
      return {
        ...state,
        profile: action.payload
      };

    case KEYWORD:
      console.log("KEYWORD -- Action", action);
      console.log("KEYWORD-- Payload", action.payload);

      return {
        ...state,
        text: action.payload
      };

    default:
      return state;
  }
};
