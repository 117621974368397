import React from "react";
import i18next from "i18next";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
          <footer className="footer" style={{ position: 'initial' }}>
            <div className="container-fluid text-center">
              <div className="row">
                <div className="col-md-6 l-align lineH">
                  <a href="/imprint" style={{color: 'white'}}>{i18next.t("imprint")}</a> |&nbsp;
                  <a href="/privacy" style={{color: 'white'}}>{i18next.t("privacyPolicy")}</a>
                </div>
                <div className="col-md-6 r-align lineH">
                  <small>© DDH {currentYear}</small>
                </div>
              </div>
            </div>
          </footer>
  );
}
