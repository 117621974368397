import axios from "axios";
import { API, API_V2 } from "../urls";
import { history } from "../_helpers/history";
import { userConstants } from "../_constants/user.constants";
import { alertActions } from "../_actions/alert.actions";
import { LoginLocation, DashboardLocation } from "../locations";
import i18next from "i18next";

export const userService = {
  culture,
  fgt,
  Reset,
  getStats,
  updateUser,
  logout,
  getToken,
  getUserId,
  checkSuperAdmin,
  isSuperAdmin,
  isCompanyAdmin,
  isDoctor,
  isCompanySubUser,
  isLoggedIn,
  statustext,
  sourcesStatus,
  crawledStatus,
  crawlStatusText,
  gridStyle,
  inviteExternal,
  verifyEmail,
  resendVerificationEmail,
  login,
  registerDoctor,
  checkGLN
};

function culture(lang) {
  const data = { culture: lang };
  return axios.post(API.cultureLocation.toUrl(data));
}

function fgt(email) {
  return axios
    .put(API.recoveryLocation.toUrl({ id: email }), {})
    .then((response) => {
      return response;
    });
}

function Reset(data) {
  return axios.put(API.resetLocation.toUrl(), data).then((response) => {
    return response;
  });
}

function getStats() {
  return axios.get(API.statsLocation.toUrl());
}

function updateUser(data) {
  return axios.post(API.updateDoctorLocation.toUrl(), data);
}

function logout() {
  localStorage.removeItem("user");
  history.push(LoginLocation.path);
  return { type: userConstants.LOGOUT };
}

function getToken() {
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user && user.token ? user.token : "";

  return token;
}

function getUserId() {
  let user = JSON.parse(localStorage.getItem("user"));
  let id = user && user.id ? user.id : "";
  return id;
}

function checkSuperAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.rolesDescription !== undefined)
    return user.rolesDescription.includes("SuperAdmin") ? "true" : "false";
  else return "false";
}

function isSuperAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    user &&
    user.rolesDescription &&
    user.rolesDescription.includes("SuperAdmin")
  );
}

function isCompanyAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    user && user.rolesDescription && user.rolesDescription.includes("Company")
  );
}

function isDoctor() {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    user &&
    user.rolesDescription &&
    user.rolesDescription.includes("Doctor") &&
    !isCompanyAdmin() &&
    !isSuperAdmin()
  );
}

function isCompanySubUser() {
  return !isCompanyAdmin() && !isSuperAdmin();
}

function isLoggedIn() {
  let b = localStorage.getItem("user") ? true : false;
  return b;
}

function statustext(status) {
  status = String(status);
  if (status === "0") {
    return "Deactivated";
  } else if (status === "1") {
    return "Active";
  } else if (status === "2") {
    return "Default";
  } else if (status === "3") {
    return "Company Default";
  } else {
  }
}

function sourcesStatus(status) {
  status = String(status);
  if (status === "0") {
    return [i18next.t("pending")];
  } else if (status === "1") {
    return [i18next.t("crawled")];
  } else if (status === "2") {
    return [i18next.t("requested")];
  } else if (status === "3") {
    return [i18next.t("disabled")];
  } else if (status === "4") {
    return [i18next.t("completed")];
  } else if (status === "5") {
    return [i18next.t("migrated")];
  } else {
  }
}

function crawledStatus(status) {
  return status === true ? i18next.t("yes") : i18next.t("no");
}

function crawlStatusText(status) {
  switch (status) {
    case 0:
      return i18next.t("neverCrawled");
    case 1:
      return i18next.t("recentlyCrawled");
    case 2:
      return i18next.t("staleCrawl");
    case 3:
      return i18next.t("nextCrawling");
    default:
      return i18next.t("unknownStatus");
  }
}

function gridStyle() {
  return {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #eee",
      },
    },
    rows: {
      style: {
        borderRight: "1px solid #eee",
      },
    },
  };
}
function inviteExternal(email, userID) {
  let url = API.inviteExternalLocation.toUrl({ email, userID });
  return axios.post(url);
}

async function verifyEmail(token, email) {
  try {
    const response = await axios.get(API_V2.verifyEmailAPI.toUrl(), {
      params: { token, email },
      withCredentials: true,
    });
    if (response.httpStatusCode !== 200) {
      throw response.error || i18next.t("emailVerificationFailed");
    }
    return response;
  } catch (error) {
    throw error.error || error.message || i18next.t("emailVerificationFailed");
  }
}

async function resendVerificationEmail(emailOrUsername) {
  try {
    const response = await axios.get(API_V2.resendVerificationEmailAPI.toUrl(), {
      params: { emailOrUsername },
      withCredentials: true,
    });

    if (response.httpStatusCode !== 200) {
      throw response.error || i18next.t("emailResendFailed");
    }
    return response;
  } catch (error) {
    throw error.error || error.message || i18next.t("emailResendFailed");
  }
}

async function login(data, actions, dispatch) {
  try {
    const response = await axios.post(API_V2.signInAPI.toUrl(), data);
    const { httpStatusCode, error, data: userData } = response;

    if (httpStatusCode === 200) {
      const user = {
        id: userData.userId,
        email: userData.userEmail,
        userName: userData.userName,
        token: userData.token,
        rolesDescription: userData.roleNames,
      };
      localStorage.setItem("user", JSON.stringify(user));

      dispatch({ type: userConstants.LOGIN_SUCCESS, user });
      history.replace(DashboardLocation.path);
      return null;
    } else if (httpStatusCode === 403) {
      window.location.href = `/verify-email?emailOrUsername=${encodeURIComponent(data.usernameOrEmail)}`;
    } else if (httpStatusCode === 400 || httpStatusCode === 401) {
      actions.setSubmitting(false);
      dispatch({ type: userConstants.LOGIN_FAILURE, error });
      dispatch(alertActions.error(error));
      return { httpStatusCode, error };
    } else if (httpStatusCode === 402) {
      // Subscription error
      actions.setSubmitting(false);
      dispatch({ type: userConstants.LOGIN_FAILURE, error });
      dispatch(alertActions.error(error));
      return { httpStatusCode, error };
    } else {
      // Fallback
      actions.setSubmitting(false);
      dispatch({ type: userConstants.LOGIN_FAILURE, error });
      dispatch(alertActions.error(error));
      return { httpStatusCode, error };
    }
  } catch (err) {
    console.log("error", err);
    actions.setSubmitting(false);
    const errorText =
      (err && err.response && err.response.data && err.response.data.error) ||
      err.toString();

    dispatch({ type: userConstants.LOGIN_FAILURE, error: errorText });
    dispatch(alertActions.error(errorText));
    return { httpStatusCode: 500, error: errorText };
  }
}

function registerDoctor(data, onSuccess, onFailure) {
  return axios
    .post(API_V2.registerDoctorAPI.toUrl(), data, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    })
    .then(
      (response) => {
        if (response.httpStatusCode === 200) {
          onSuccess(response.message);
        } else {
          onFailure(response.error || i18next.t("registrationFailed"));
        }
      },
      (error) => {
        onFailure(error.message || i18next.t("registrationFailed"));
      }
    );
}

async function checkGLN(gln) {
  try {
    const response = await axios.get(API_V2.checkGLNAPI.toUrl(), {
      params: { gln },
    });

    if (response.httpStatusCode === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}