import React from "react";
import { Link } from "react-router-dom";
import { profile } from "../../_actions/profile.action";
import i18next from "i18next";
import { De, Fr, Gb, It } from 'react-flags-select';
import { userService } from "../../_services/user.service";
import ddhLogo from "../../assets/img/brand/ddh-logo.png";


export default class NavBar extends React.Component {

  change(option) {
    localStorage.setItem('lang', option);
    window.location.reload();
    this.setState({ lang: localStorage.getItem('lang') || 'en' });
    const lang = localStorage.getItem('lang') || 'en';
    userService.culture(lang);

    return lang;
  }

  goStart() {
    window.location = "/dashboard";
  }

  render() {
    function onClickHandler() {
      console.log("Callin Profile");
      profile();
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3 col-lg-3">
              <a onClick={this.goStart} className="navbar-brand">
                <img src={ddhLogo} width="200px" />
              </a>
            </div>
            <br></br>
            <div className="col-9">
              <ul className="navbar-nav mr-auto navbar-right">
                <li className="nav-item">
                  <Link
                    to="/profile"
                    onClick={onClickHandler}
                    className="nav-link a-color"
                  >
                    <span className="glyphicon glyphicon-user" /> {i18next.t("myprofile")}
                  </Link>
                </li>
                {!this.props.isHome ?
                  <li className="nav-item">
                    <Link
                      to="/chatroom"
                      onClick={onClickHandler}
                      className="nav-link a-color"
                    >
                      <span className="glyphicon glyphicon-user" /> {i18next.t("chatroom")}
                    </Link>
                  </li>
                  : null}
                {!this.props.isHome ?
                  <li className="nav-item">
                    <a href="/messages" className="nav-link a-color">
                      <span className="glyphicon glyphicon-inbox" /> {i18next.t("mediHotline")}
                    </a>
                  </li>
                  : null}
                <li className="nav-item">
                  <a href="/support" className="nav-link a-color">
                    <span className="glyphicon glyphicon-inbox" /> {i18next.t("support")}
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/logout" className="nav-link a-color">
                    <span className="glyphicon glyphicon-log-in" /> {i18next.t("logout")}
                  </a>
                </li>


                <li className="nav-item">
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 15,
                    cursor: "pointer"
                  }}>
                    <De onClick={() => this.change("de")} /> &nbsp;&nbsp; <Gb
                      onClick={() => this.change("en")} />&nbsp;&nbsp; <Fr onClick={() => this.change("fr")} />&nbsp;&nbsp;
                    <It onClick={() => this.change("it")} />
                  </div>

                </li>


              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
