import React from "react";
import Navbar from "./navBar";
import Footer from "./footer";
import "../dashboard/search/test.css";
import { messagesService } from "../../_services/messages.service";
import { cogoToastHelper } from "../../_helpers/cogoToastHelper";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { userService } from "../../_services/user.service";
import { connect } from "react-redux";
import SearchBar from "./search/search-bar";
import * as signalR from "@microsoft/signalr";
import { messageHubUrl } from "../../config.example";
import Loader from "react-loader-spinner";
import { animateScroll } from "react-scroll";
import i18next from "i18next";
import Row from 'react-bootstrap/Row';
import { productService } from "../../_services/product.service";

import {
  Col,
  Button,
  Spinner,
  FormGroup,
  FormFeedback,
  Input,
} from "reactstrap";
import { companyService } from "../../_services/company.service";

const ActiveButton = (props) => {
  let buttonColor = (props.isActive === true) ? '#2d2762' : '#eeeeee';
  let textColor = (props.isActive === true) ? 'white' : '#777';

  return (
    <div className="composeNewButton" onClick={props.toggle} style={{ backgroundColor: buttonColor, color: textColor }}>
      {props.children}
    </div>
  )
}

class Messages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      msg: "",
      addedDate: "",
      user_Id_Sender: "",
      toggle: false,
      message: "",
      conversations: [],
      receiver: "",
      product_id: "",
      current_product: {},
      messagesList: [],
      currentConversation: null,
      oldConversations: [],
      search: "",
      Files: [],
      selectedFile: null,
      hubConnection: null,
      loadingMessages: false,
      newmessage: false,
      companies: [],
      allCompanies: [],
      companiesID: [],
      new__message: "",
      newmessagecompany: "",
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      suggestions: [],
      newmessagesubject: "",
      isCompanyButtonActive: true,
      isMyMessageButtonActive: false,
      productsID: [],
      products: [],
      originProducts: [],
      productsByCompany: [],
      companiesWithProducts: new Map(),
      companyDictionary: new Map(),
      selectedCompany: null,
      selectedCompanyIndex: -1,
      selectedProduct: null,
      selectedProductIndex: -1,
      isProductHotlineSelected: false,
      isProductHotlineSearchActive: false,
      isproductsByCompanyLoading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getAllCompanies();
    await this.getAllProducts();
    await this.getAllCompaniesAndTheirProducts();
    this.getSignalR();
    this.getConversation();
  }

  getCompanyNameFromProduct = (selectedProduct) => {
    for (const companyName in this.state.companiesWithProducts) {
      const products = this.state.companiesWithProducts[companyName];
      for (const product of products) {
        if (product.brandName === selectedProduct.brandName) {
          return companyName;
        }
      }
    }
    return "";
  }

  checkCompanyHasProducts = (cName) => {

    for (const companyName in this.state.companiesWithProducts) {
      if (cName === companyName) {
        const products = this.state.companiesWithProducts[companyName];
        return products.length > 0;
      }
    }
    return false;
  }

  getAllProducts = async () => {
    let result = await productService.getAllActiveProducts();
    this.setState({ products: result, originProducts: result })
  }

  getAllCompanies = async () => {
    let allCompanies = await companyService.getAllCompanies();
    var companyList = [];
    var companiesID = [];
    var dict = new Map();

    allCompanies.forEach((company) => {
      if(dict[company.companyName] === undefined){
        dict[company.companyName] = company.id;
      }
      else {
        dict[company.companyName] = dict[company.companyName] + ", " + company.id;
      }
    });

    for (var company in allCompanies) {
      companyList.push(allCompanies[company]["companyName"]);
      companiesID[allCompanies[company]["companyName"]] = allCompanies[company]["id"];
    }
    this.setState({ allCompanies: companyList, companiesID: companiesID, companyDictionary: dict });
  }

  getConversation() {
    messagesService
      .getConversation(userService.getUserId())
      .then((conversations) => {
        this.setState({ conversations: conversations });
        this.setState({ oldConversations: conversations });

        if (this.state.currentConversation === null) {
          this.readMessage(conversations[0]);
        }
      });
  }

  getConversationByProduct = (product) => {
    const conversation = this.state.conversations;
    const searchInConversationbyCompany = conversation.find(c => c.productId === product.id);

    if (searchInConversationbyCompany === undefined) {
      this.setState({ newmessage: true });
    } else {
      this.readMessage(searchInConversationbyCompany);
    }
  }

  getAllCompaniesAndTheirProducts = async () => {
    const allCompanies = await companyService.getAllCompanies();
    const products = this.state.products;
    const companiesWithProducts = this.state.companiesWithProducts;
    const companyDictionary = this.state.companyDictionary;

    allCompanies.forEach((company) => {
      var companyIds = companyDictionary[company.companyName].split(", ");
      for(var i = 0; i < companyIds.length; i++){
        const productsForCompany = products.filter((products) => products.addedBy === companyIds[i]);
        if (productsForCompany.length > 0) {
          companiesWithProducts[company.companyName] = productsForCompany;
        }
      }

      // const productsForCompany = products.filter((products) => products.addedBy === company.id);
      // companiesWithProducts[company.companyName] = productsForCompany;
    });
    this.setState({ companiesWithProducts: companiesWithProducts, isproductsByCompanyLoading: false });
  }

  getCompaniesWithoutDublikate = () => {
    const uniqueArray = this.state.allCompanies.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    return uniqueArray;
  }

  formateShortDate(date) {
    let newDate = new Date(date);
    newDate = String(newDate).replace("GMT+0200 (CEST)", "");
    return newDate.substring(0, newDate.length - 15);
  }

  getSignalR = async () => {
    let hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(messageHubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          hubConnection.invoke("getConnected", userService.getUserId());
        })
        .catch((err) => console.log("Error while establishing connection :("));
    });

    hubConnection.on("receiveMessage", (message) => {
      cogoToastHelper.success(
        "You have recieved a new message from " + message.user_Sender_Name
      );
    });

    hubConnection.on("getConnection", (message) => {
    });

    hubConnection.onclose(async () => {
      await this.getSignalR();
    });
    hubConnection.serverTimeoutInMilliseconds = 100000000;
  };

  onChange(data) {
    this.setState({ message: data });
  }

  onNewMsgChange(data) {
    this.setState({ new__message: data });
  }

  toggleProps() {
    if (this.state.toggle === true) {
      this.setState({ toggle: false });
    }
    if (this.state.toggle === false) {
      this.setState({ toggle: true });
    }
  }

  toggleReply() {
    if (userService.getUserId() === this.state.current_product.user_Id_Sender) {
      this.setState({ receiver: this.state.current_product.user_Id_Receiver });
    } else {
      this.setState({ receiver: this.state.current_product.user_Id_Sender });
    }
    this.setState({ product_id: this.state.current_product.productId });
    this.toggleProps();
  }

  readMessage(conversation) {
    this.setState({ loadingMessages: true, newmessage: false });
    if (conversation != null) {
      messagesService.getMessage(conversation.conversationID).then((messages) => {
        this.setState({
          messagesList: messages,
          currentConversation: conversation,
        });
        this.setState({ loadingMessages: false });
        this.scrollToBottom();
      });
    }
  }

  formateDate(date) {
    let newDate = new Date(date);
    newDate = String(newDate).replace("GMT+0200 (CEST)", "");
    return newDate.substring(0, 10);
  }

  formateFullDate(date) {
    let newDate = new Date(date);
    let month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    return (


      newDate.getFullYear() +
      "-" +
      // newDate.getMonth() +
      month[newDate.getMonth()] +
      "-" +
      newDate.getDate() +
      " " +
      newDate.getHours() +
      ":" +
      newDate.getMinutes()
    );
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleKeyUp = () => {
    if (this.state.isMyMessageButtonActive) {
      this.handleMessageKeyUp();
    }
    if (this.state.isCompanyButtonActive) {
      this.handleProductHotlineKeyUp();
    }
  };

  handleMessageKeyUp = () => {
    if (this.state.search !== "") {
      let searchTxt = this.state.search;
      var newArray = this.state.oldConversations.filter(function (chat) {
        return chat.subject.includes(searchTxt);
      });
      this.setState({ conversations: newArray });
    }
    else {
      this.setState({ conversations: this.state.oldConversations });
    }
  }

  handleProductHotlineKeyUp = () => {
    // show all products if the search is empty
    if (this.state.search === "") {
      this.setState({ products: this.state.originProducts, isProductHotlineSearchActive: false });
    }

    // prevent the user from searching for only spaces
    else if (this.state.search.trim() !== "") {

      let prods = [];
      let searchTxt = this.state.search.toLowerCase();

      for (const companyName in this.state.companiesWithProducts) {
        // check if the search matches the company name
        // if (companyName.toLowerCase().includes(searchTxt)) {
        //   prods.push(companyName);
        // }

        // check if the search matches the product name
        const products = this.state.companiesWithProducts[companyName];
        for (const product of products) {
          if (product.brandName.toLowerCase().includes(searchTxt)) {
            prods.push(product);
          }
        }
      }

      this.setState({ products: prods, isProductHotlineSearchActive: true });
    }
  }

  handleChange(event) {
    if (this.state.isCompanyButtonActive) {
      this.setState({ search: event.target.value, isProductHotlineSearchActive: true, selectedProductIndex: -1, selectedCompanyIndex: -1 });
    } else {
      this.setState({ search: event.target.value, isProductHotlineSearchActive: false });
    }
  }

  handlePressCompanySelected = async (company, index) => {
    var productsByCompany = this.state.companiesWithProducts[company];
    this.setState({ selectedCompany: company, productsByCompany: productsByCompany, selectedCompanyIndex: index});
  }

  handlePressProductSelected = (product, index) => {
    this.getConversationByProduct(product);
    var ownerProduct = this.getCompanyNameFromProduct(product);
    this.setState({ isProductHotlineSelected: true, selectedProduct: product, selectedCompany: ownerProduct, selectedProductIndex: index })
  }

  toggleActiveButton = () => {
    this.setState({
      isCompanyButtonActive: !this.state.isCompanyButtonActive,
      isMyMessageButtonActive: !this.state.isMyMessageButtonActive,
      search: ""
    });
  }

  NewMsg() {
    this.setState({ newmessage: !this.state.newmessage });
  }

  scrollToBottom = () => {
    const IS_SCROLl_TO_BOTTOM_ENABLE_WHEN_NO_CONVERSATION_SELECTED = ((this.state.selectedProduct != null && this.state.isCompanyButtonActive && !this.state.newmessage) || this.state.isMyMessageButtonActive)
    if (IS_SCROLl_TO_BOTTOM_ENABLE_WHEN_NO_CONVERSATION_SELECTED)
      setTimeout(function () {
        animateScroll.scrollToBottom({
          containerId: "ContainerElementID",
        });
      }, 3000);
  };

  onChangeNewMessageCompany = (e) => {
    const userInput = e.currentTarget.value;
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = this.state.allCompanies.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
        isMyMessageButtonActive: true,
        isCompanyButtonActive: false,
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  renderCreateMessageButton = () => {
    return (
      <div className="composeNewWrapper">
        <div className="composeNew" onClick={() => this.setState({ newmessage: true })}>
          {i18next.t("composemessage")}
        </div>
      </div>
    )
  }

  renderInternalSearchbar = () => {
    return (
      <Form>
        <FormGroup row className="nopaddings search_bar">
          <Col sm={1} className="nopaddings">
            <i className="icon-search"></i>
          </Col>
          <Col sm={11} className="nopaddings">
            <Input
              type="text"
              placeholder={i18next.t("search")}
              onKeyUp={this.handleKeyUp}
              onChange={this.handleChange}
              value={this.state.search}
            />
          </Col>
        </FormGroup>
      </Form>
    )
  }

  renderRowElement = (str, selectedIndex, index, clickOnRowElementEventFunction) => {
    let styleIfElementSelected = {};
    let styleIfElementSelectedText = {};

    if ((selectedIndex === index)) {
      // Circle with initials
      styleIfElementSelected.backgroundColor = '#2d2762';
      styleIfElementSelected.color = 'white';
      styleIfElementSelected.borderColor = '#2d2762';

      // Text
      styleIfElementSelectedText.textDecoration = 'underline #2d2762 2px';
    }

    return (
      <div key={index} onClick={clickOnRowElementEventFunction}>
        <Row className="chatGroupEntry" style={{flexWrap: 'noWrap'}}>
          <div className="chatGroupEntryImageContainer col-sm-2" style={{...styleIfElementSelected, maxWidth: "40px"}}>
            {this.getLiteral(str)}
          </div>
          <div className="chatGroupEntryLabel">
            <div className="chatGroupEntryTitle" style={styleIfElementSelectedText}>{str}</div>
          </div>
        </Row>
      </div>
    )
  }

  renderProductSearchResult = () => {
    if (!this.state.isProductHotlineSearchActive || this.state.isMyMessageButtonActive) { return }

    if (this.state.products.length > 0) {
      return (
        this.state.products.map((product, index) => {
          return (
            this.renderRowElement(product.brandName, this.state.selectedProductIndex, index, () => { this.handlePressProductSelected(product, index) })
          );
        })
      )
    }
    else {
      return <p className="selectCompany">{i18next.t('noProductHotlineWereFound')}</p>
    }
  }

  renderSelectedCompanyTextNote = () => {
    if (this.state.isProductHotlineSearchActive) { return }
    if (this.state.productsByCompany.length === 0 && this.state.selectedCompany != null) {
      return <p className="selectCompany">{i18next.t("noProductsFound") + "..."}</p>
    }
    return <p className="selectCompany">{i18next.t("pleaseSelectCompany")}</p>
  }

  renderCompanyforProductSearch = () => {
    if (this.state.isProductHotlineSearchActive) {
      return (
        this.state.products.map((product, index) => {
          var ownerProduct = this.getCompanyNameFromProduct(product);
          return (
            this.renderRowElement(ownerProduct, this.state.selectedProductIndex, index, () => { this.handlePressProductSelected(product, index) })
          );
        })
      )
    }
  }


  renderCompanyProductList = () => {
    if (this.state.isproductsByCompanyLoading === true) {
      return (<center>
        <Loader type="ThreeDots" color="#00BFFF"></Loader>
      </center>)
    } else {
      //if no company has been selected, show all products
      if (!this.state.selectedCompany) {
        return (
          this.state.products.map((product, index) => {
            return (
              this.renderRowElement(product.brandName, this.state.selectedProductIndex, index, () => { this.handlePressProductSelected(product, index) })
            );
          })
        )
      }
      //if the selected company has no products, show nothing
      else if (!this.checkCompanyHasProducts(this.state.selectedCompany)) {
        return (
          <></>
        )
      }
      //if a company has been selected, show the products of the selected company
      else {
        return (
          this.state.companiesWithProducts[this.state.selectedCompany].map((product, index) => {
            return (
              this.renderRowElement(product.brandName, this.state.selectedProductIndex, index, () => { this.handlePressProductSelected(product, index) })
            );
          })
        )
      }
    }
  }

  getLiteral = (name) => {
    const pattern = /([A-Z])/g;
    const match = pattern.exec(name.toString().substring(1, name.length));
    const firstLetter = name.charAt(0).toUpperCase();
    if (match) {
      const secondLetter = match[1];
      return firstLetter + secondLetter;
    } else {
      return firstLetter;
    }
  }

  render() {
    const {
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No company found with this name</em>
          </div>
        );
      }
    }
    const CONDOTION_TO_SEE_THE_MESSAGES = (this.state.newmessage === false) && ((this.state.isProductHotlineSelected) || this.state.isMyMessageButtonActive);

    return (
      <React.Fragment>
        <Navbar />
        <div className="row">
          <div className="col-sm-8 offset-sm-2">
            <SearchBar />
          </div>
        </div>
        <br></br>
        <div className="row nopaddings">
          <div className="col-md-10 offset-1">
            <div className="row inboxContainer">
              <div className="col-md r-line nopaddings messageGroup">
                <div className="producthotlineInfo" >
                  <p className="producthotlineInfoText">{i18next.t("availibleHotlines")} - {i18next.t("myMessages")}</p>
                </div>
                {this.renderInternalSearchbar()}
                <div className="headerButton">
                  <ActiveButton isActive={this.state.isCompanyButtonActive} toggle={() => this.toggleActiveButton()}  >{i18next.t("availibleHotlines")}</ActiveButton>
                  <ActiveButton isActive={this.state.isMyMessageButtonActive} toggle={() => { this.toggleActiveButton(); this.setState({ isProductHotlineSearchActive: false, search: "" }) }}  >{i18next.t("myMessages")}</ActiveButton>
                </div>
                <div className="row inboxContainer"></div>
                <div class="col-12">
                  {
                    //(this.state.isCompanyButtonActive || this.state.isProductHotlineSearchActive)
                    (this.state.isCompanyButtonActive)
                    ?
                      <div class="row flex-wrap flex-row">
                        {
                          !this.state.isProductHotlineSearchActive
                          ?
                            <div class="col-xl-6">
                              <div>
                                <p className="columnHeaderHotlines">{i18next.t('companies')}</p>
                                <p className="b-line" />
                              </div>
                              {this.getCompaniesWithoutDublikate().map((company, index) => {
                                return (
                                  this.renderRowElement(company, this.state.selectedCompanyIndex, index, () => this.handlePressCompanySelected(company, index))
                                );
                              })}
                            </div>
                          :
                            // Companies are shown for each found product
                            <div class="col-xl-6">
                              <div>
                                <p className="columnHeaderHotlines">{i18next.t('companies')}</p>
                                <p className="b-line" />
                              </div>
                              {this.renderCompanyforProductSearch()}
                            </div>
                        }
                        <div class="col-xl-6">
                          <div>
                            <p className="columnHeaderHotlines">{i18next.t("products")}</p>
                            <p className="b-line" />
                          </div>
                          <div className="">
                            {this.renderCompanyProductList()}
                          </div>
                        </div>
                      </div>
                    :
                      null
                  }
                  </div>
                {(this.state.isMyMessageButtonActive)
                  ?
                  <div className="">
                    {this.state.conversations.map((conversation, index) => {
                      return (
                        <div key={index} className="row mail nopaddings b-line">
                          <div
                            className="col-12 nopaddings"
                            onClick={() => this.readMessage(conversation)}
                          >
                            <div className="row nopaddings">
                              <div className="col-1 nopaddings cursor circleWrapper">
                                <div className="circleProductHotline">
                                  {conversation.user_Reciever_Name.substring(0, 1)}
                                </div>
                              </div>
                              <div className="col-10 cursor">
                                <div className="row nopaddings">
                                  <div className="col-8 nopaddings">
                                    <p className="mailName mheader">
                                      {conversation.user_Reciever_Name}
                                    </p>
                                  </div>
                                  <div className="col-4 nopaddings r-align">
                                    <p className="mailName mheader">
                                      {this.formateDate(conversation.modifiedDate)}
                                    </p>
                                  </div>
                                  <div className="col-11 nopaddings mheader">
                                    <b className="subject">
                                      {conversation.productName}
                                    </b>
                                    <p className="messageContent">
                                      {conversation.subject}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  :
                  null
                }
              </div>
              <div className="col-md-6 col-sm-12 inboxMsg nopaddings">
                <br />
                {this.state.loadingMessages && (
                  <center>
                    <Loader type="ThreeDots" color="#00BFFF"></Loader>
                  </center>
                )}
                {(CONDOTION_TO_SEE_THE_MESSAGES) ? (
                  <div>
                    <div className="messages_list" id="ContainerElementID">
                      {this.state.messagesList.map((messages, index) => {
                        if (
                          userService.getUserId() === messages.user_Sender_Id
                        ) {
                          return (
                            <div key={index} className="row nopaddings  inboxMail">
                              <div className="col-md-3 content "></div>
                              <div className="col-md-9 content bline r-align grayedge">
                                <div className="sendercircle">
                                  {messages.user_Sender_Name.substring(0, 1)}
                                </div>
                                {messages.messageContent.contentType === 0 && (
                                  <p>{messages.messageContent.text}</p>
                                )}
                                {messages.messageContent.contentType === 1 && (
                                  <p>
                                    <img alt="" src={messages.messageContent.url} />
                                  </p>
                                )}
                                {messages.messageContent.contentType === 5 && (
                                  <p>
                                    <img src={messages.messageContent.url} />
                                    <br />
                                    {messages.messageContent.text}
                                  </p>
                                )}
                                <p className="mailName r-align mheader">
                                  {i18next.t("senton")} :{" "}
                                  {this.formateFullDate(messages.modifiedDate)}
                                </p>
                              </div>
                              <br />
                              <br />
                              <br />
                            </div>
                          );
                        } else {
                          return (
                            <div key={index} className="row nopaddings  inboxMail">
                              <div className="col-md-9 content bline blueedge">
                                <div className="circle">
                                  {messages.user_Reciever_Name.substring(0, 1)}
                                </div>
                                {messages.messageContent.contentType === 0 && (
                                  <p>{messages.messageContent.text}</p>
                                )}
                                {messages.messageContent.contentType === 1 && (
                                  <p>
                                    <img src={messages.messageContent.url} />
                                  </p>
                                )}
                                {messages.messageContent.contentType === 5 && (
                                  <p>
                                    <img src={messages.messageContent.url} />
                                    <br />
                                    {messages.messageContent.text}
                                  </p>
                                )}
                                <p className="mailName l-align mheader">
                                  {i18next.t("senton")} :{" "}
                                  {this.formateFullDate(messages.modifiedDate)}
                                </p>
                              </div>
                              <div className="col-md-3 content"></div>
                              <br />
                              <br />
                              <br />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="new_messages">
                      <Formik
                        initialValues={{
                          message: this.state.message,
                        }}
                        validationSchema={Yup.object().shape({})}
                        onSubmit={(data, actions) => {
                          //body
                          let contentType = 0;
                          if (this.state.selectedFile !== null) contentType = 1;

                          if (
                            this.state.selectedFile !== null &&
                            this.state.message !== ""
                          )
                            contentType = 5;

                          let messageContent = JSON.stringify({
                            Text: this.state.message,
                            ContentType: contentType,
                            Files: [],
                          });

                          let formData = new FormData();
                          formData.append(
                            "User_id_Sender",
                            userService.getUserId()
                          );
                          formData.append(
                            "User_id_Reciever",
                            this.state.currentConversation.user_Reciever_Id
                          );
                          formData.append(
                            "ProductId",
                            Number(this.state.currentConversation.productId)
                          );
                          formData.append("MessageContent", messageContent);
                          formData.append(
                            "ConversationId",
                            Number(
                              this.state.currentConversation.conversationID
                            )
                          );
                          formData.append("Files", this.state.selectedFile);
                          messagesService
                            .sendMessage(formData)
                            .then((messages) => {
                              this.setState({ message: '' });
                              actions.setSubmitting(false);
                              this.readMessage(this.state.currentConversation);
                            });
                        }}
                        render={({
                          values,
                          errors,
                          status,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <Form action="" method="post" className="messageForm">
                            <FormGroup>
                              <Input
                                type="textarea"
                                id="message"
                                name="message"
                                placeholder={i18next.t("entermessage")}
                                value={this.state.message}
                                onChange={(e) =>
                                  this.onChange(`${e.target.value}`)
                                }
                                invalid={errors.message && touched.message}
                                valid={this.state.message !== ""}
                              />
                              <FormFeedback>{errors.message}</FormFeedback>
                            </FormGroup>
                            {!isSubmitting && (
                              <Col sm="12" md="12" style={{ padding: 0 }}>
                                <Button
                                  className="cardButton sendReply"
                                  block
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {i18next.t("sendreply1")}
                                </Button>
                                <i
                                  className="icon-paper-clip attachment"
                                  onClick={() => this.inputElement.click()}
                                ></i>
                                <input
                                  type="file"
                                  onChange={this.onFileChange}
                                  className="imageUpload"
                                  ref={(input) => (this.inputElement = input)}
                                />
                              </Col>
                            )}
                            {isSubmitting && <Spinner color="success" />}
                          </Form>
                        )}
                      />
                    </div>
                  </div>
                )
                  : null
                }
                {this.state.newmessage === true && (
                  <div className="new_messages">
                    <Formik
                      initialValues={{
                        message: this.state.new__message,
                        comapany: this.state.newmessagecompany,
                        newmessagesubject: this.state.newmessagesubject
                      }}
                      validationSchema={Yup.object().shape({})}
                      onSubmit={(data, actions) => {
                        //body
                        let contentType = 0;
                        if (this.state.selectedFile !== null) contentType = 1;

                        if (
                          this.state.selectedFile !== null &&
                          this.state.new__message !== ""
                        )
                          contentType = 5;

                        const CONDITIONS_DETERMINE_WHETHER_AND_WHICH_PRODUC_HOTLINE_ON_WRITTEN = this.state.selectedProduct != null && this.state.isProductHotlineSelected;
                        let productId = (CONDITIONS_DETERMINE_WHETHER_AND_WHICH_PRODUC_HOTLINE_ON_WRITTEN) ? this.state.selectedProduct.id : "0"
                        let receiver = (CONDITIONS_DETERMINE_WHETHER_AND_WHICH_PRODUC_HOTLINE_ON_WRITTEN) ? this.state.companiesID[this.state.selectedCompany] : this.state.companiesID[this.state.userInput]
                        let formData = {
                          "Text": this.state.new__message,
                          "Subject": this.state.selectedProduct.brandName,
                          "User_id_Sender": userService.getUserId(),
                          "User_id_Reciever": receiver,
                          "ProductID": productId
                        }

                        messagesService.createMessage(formData)
                          .then(
                            response => {
                              actions.setSubmitting(false);
                              this.setState({ newmessage: false });
                            },
                            error => {
                              actions.setSubmitting(false);
                              this.setState({ newmessage: false });
                              cogoToastHelper.error((error.toString()))
                            }
                          );
                      }}
                      render={({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form
                          action=""
                          method="post"
                          className="newmessageForm"
                        >

                          <FormGroup>

                            <Input
                              id="subject"
                              name="subject"
                              type="text"
                              placeholder={i18next.t("subject")}
                              value={this.state.selectedProduct.brandName}
                              onChange={(e) =>
                                this.setState({ newmessagesubject: `${e.target.value}` })
                              }
                              invalid={
                                errors.newmessagesubject && touched.newmessagesubject
                              }
                              className="newmessageFormInput"
                              valid={this.state.newmessagesubject != ""}
                            />

                            <FormFeedback>{errors.new__message}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            {
                              <input
                                type="text"
                                onChange={this.onChangeNewMessageCompany}
                                placeholder={i18next.t("searchcompanybyname")}
                                onKeyDown={onKeyDown}
                                value={this.getCompanyNameFromProduct(this.state.selectedProduct)
                                }
                                name="company"
                                className="newmessageFormInput"
                              />
                            }
                            {suggestionsListComponent}
                            <FormFeedback>{errors.message}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="textarea"
                              id="message"
                              name="message"
                              placeholder={i18next.t("entermessage")}
                              onChange={(e) =>
                                this.onNewMsgChange(`${e.target.value}`)
                              }
                              invalid={
                                errors.new__message && touched.new__message
                              }
                              valid={this.state.new__message}
                            />
                            <FormFeedback>{errors.new__message}</FormFeedback>
                          </FormGroup>
                          {!isSubmitting && (
                            <Col sm="12" md="12" style={{ padding: 0 }}>
                              <Button
                                className="cardButton sendReply"
                                block
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {i18next.t("sendreply1")}
                              </Button>
                              <i
                                className="icon-paper-clip attachment"
                                onClick={() => this.inputElement.click()}
                              ></i>
                              <input
                                type="file"
                                onChange={this.onFileChange}
                                className="imageUpload"
                                ref={(input) => (this.inputElement = input)}
                              />
                            </Col>
                          )}
                          {isSubmitting && <Spinner color="success" />}
                        </Form>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.product.profile,
});

export default connect(mapStateToProps)(Messages);