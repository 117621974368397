import axios from "axios";
import { API } from "../urls";
import { userService } from "../_services/user.service";

export const productService = {
  getAllProducts,
  newProduct,
  getProduct,
  newProductReview,
  updateProduct,
  getProductImages,
  getProductImagesLang,
  getProductReviews,
  newProductReviewComment,
  publishProduct,
  assignProductToSubUser,
  sendMessageToCompany,
  getMessage,
  getProductFiles,
  getProductFilesLang,
  productStatus,
  deleteProductImages,
  getProductLink,
  getProductVideos,
  getProductVideosLang,
  deleteProductVideo,
  getProductLinks,
  getProductLinksLang,
  getDeleteLinks,
  updateProductFile,
  updateLiteratureProduct,
  updatePInformationProduct,
  getProductPacks,
  getProductPacksLang,
  createProductPacks,
  deleteProductPack,
  updateProductPacks,
  updateCompanyLocation,
  productPatInformationLocation,
  productPatInformationLocationLang,
  updatePatientInfoFile,
  deleteProduct,
  getAssignPermissions,
  updateProductAssignment,
  deleteProductToSubUser,
  getProductLiteratureLinks,
  getProductLiteratureLinksLang,
  createLiteratureLink,
  deleteProductLiteratureLink,
  updateLiteratureLink,
  getProductInfoFile,
  getProductInfoFileLang,
  updateProductInfoFile,
  deleteInfoFile,
  getAllActiveProducts,
  getAllCompanyProductsByCompanyId,
  getProductHotline,
  getAllProductHotlinesByCompany,
  addProductHotline,
  updateProductHotline,
  deleteProductHotline
};

const token = userService.getToken();

function getAllProducts() {
  let url = API.productsLocation.toUrl();
  return axios.get(url);
}

function getProduct(id) {
  let url = API.productLocation.toUrl({ id });
  return axios.get(url);
}

function getProductLink(data) {
  let url = API.productLinksLocation.toUrl();
  return axios.post(url, data);
}

function deleteProductImages(productId, fileType) {
  let url = API.productDeleteFile.toUrl({ id: productId, type: fileType });
  return axios.delete(url);
}

function deleteProductVideos(productId) {
  let url = API.productDeleteVideo.toUrl({ id: productId });
  return axios.delete(url);
}


function deleteProduct(id) {
  let url = API.productLocation.toUrl({ id: id });
  return axios.delete(url);
}

function deleteProductPack(packId) {
  let url = API.productPacksUpdateLocation.toUrl({ id: packId });
  return axios.delete(url);
}


function deleteProductVideo(productId) {
  let url = API.productVideoLocation.toUrl({ id: productId });
  return axios.delete(url);
}

function getProductReviews(productId) {
  let url = API.productReviewLocation.toUrl({ id: productId });
  return axios.get(url);
}

function getProductInfoFile(productId) {
  let url = API.productInfoFilesLocation.toUrl({ id: productId });
  return axios.get(url);
}

function getProductInfoFileLang(productId, langCode) {
  let url = API.productInfoFilesLocationLang.toUrl({ id: productId, langCode: langCode });
  return axios.get(url);
}

function getProductImages(id) {
  let url = API.productImageLocation.toUrl({ id });
  return axios.get(url);
}

function getProductImagesLang(id, langCode) {
  let url = API.productImageLocationLang.toUrl({ id, langCode });
  return axios.get(url);
}

function getProductFiles(id) {
  let url = API.productFileLocation.toUrl({ id });
  return axios.get(url);
}

function getProductFilesLang(id, langCode) {
  let url = API.productFileLocationLang.toUrl({ id, langCode });
  return axios.get(url);
}

function getProductLiteratureLinks(id) {
  let url = API.productLiteratureLinksCreate.toUrl({ id });
  return axios.get(url);
}

function getProductLiteratureLinksLang(id, langCode) {
  let url = API.productLiteratureLinksCreateLang.toUrl({ id, langCode });
  return axios.get(url);
}

function productPatInformationLocation(id) {
  let url = API.productPatInformationLocation.toUrl({ id });
  return axios.get(url);
}

function productPatInformationLocationLang(id, langCode) {
  let url = API.productPatInformationLocationLang.toUrl({ id, langCode });
  return axios.get(url);
}

function getProductPacks(id) {
  let url = API.productPacksGetLocation.toUrl({ id: id });
  return axios.get(url);
}

function getProductPacksLang(id, langCode) {
  let url = API.productPacksGetLocationLang.toUrl({ id: id, langCode: langCode });
  return axios.get(url);
}

function updateProductPacks(id, data) {
  let url = API.productPacksUpdateLocation.toUrl({ id });
  return axios.put(url, data);
}

function updateProductInfoFile(id, data) {
  let url = API.productInfoFilesLocation.toUrl({ id });
  return axios.put(url, data);
}

function deleteInfoFile(id) {
  let url = API.productInfoFilesLocation.toUrl({ id });
  return axios.delete(url);
}

function createProductPacks(data) {
  let url = API.productPacksLocation.toUrl();
  return axios.post(url, data);
}

function createLiteratureLink(data) {
  let url = API.productLiteratureLinks.toUrl();
  return axios.post(url, data);
}

function getProductLinks(id) {
  let url = API.productLinkListLocation.toUrl({ id });
  return axios.get(url);
}

function getProductLinksLang(id, langCode) {
  let url = API.productLinkListLocationLang.toUrl({ id, langCode });
  return axios.get(url);
}

function getDeleteLinks(id) {
  let url = API.productLinkListLocation.toUrl({ id });
  return axios.delete(url);
}

function getProductVideos(id) {
  let url = API.productVideoLocation.toUrl({ id });
  return axios.get(url);
}

function getProductVideosLang(id, langCode) {
  let url = API.productVideoLocationLang.toUrl({ id, langCode });
  return axios.get(url);
}

function updateProduct(product) {
  console.log("UPDATION CALLED");
  console.log("Product Update", product);
  let url = API.productUpdateLocation.toUrl();
  return axios.put(url, product);
}

function updateCompanyLocation(product) {
  let url = API.productUpdateCompanyLocation.toUrl();
  return axios.put(url, product);
}


function newProduct(data) {
  let url = API.productCreateLocation.toUrl();
  return axios.post(url, data);
}

function sendMessageToCompany(data) {
  let url = API.sendMesageLocation.toUrl();
  return axios.post(url, data);
}

function updateProductFile(id, data) {
  let url = API.updateProductFileLocation.toUrl({ id: id });
  return axios.put(url, data);
}

function updateLiteratureLink(id, data) {
  let url = API.productLiteratureLinksUpdate.toUrl({ id: id });
  return axios.put(url, data);
}

function updatePatientInfoFile(id, data) {
  let url = API.updatePatientInfoFile.toUrl({ id: id });
  return axios.put(url, data);
}

function updateLiteratureProduct(id, data) {
  let url = API.updateLiteratureProductLocation.toUrl({ id: id });
  return axios.put(url, data);
}

function updatePInformationProduct(id, data) {
  let url = API.updatePInformationLocation.toUrl({ id: id });
  return axios.put(url, data);
}

function newProductReview(productId, review) {
  let url = API.productReviewCreateLocation.toUrl({ id: productId });
  return axios.post(url, review);
}

function newProductReviewComment(review, comment) {
  let url = API.productReviewCommentCreateLocation.toUrl({
    id: review.productId,
    reviewId: review.id
  });
  return axios.post(url, comment);
}

function publishProduct(id) {
  let url = API.publishProduct.toUrl({
    id: id
  });
  return axios.post(url, id);
}

function productStatus(id) {
  let url = API.productStatus.toUrl({
    id: id
  });
  return axios.put(url, id);
}

function permissionToProduct(id) {
  let url = API.permissionToProduct.toUrl({
    id: id
  });
  return axios.post(url, id);
}

function getMessage(id) {
  let url = API.getMessages.toUrl({
    id: id
  });
  return axios.get(url, id);
}

function assignProductToSubUser(id, data) {
  let url = API.assignProduct.toUrl({
    id: id
  });
  return axios.post(url, data);
}

function deleteProductToSubUser(data) {
  let url = API.updateAssignedPermissions.toUrl();
  return axios.put(url, data);
}

function deleteProductLiteratureLink(id) {
  let url = API.productLiteratureLinksUpdate.toUrl({ id: id });
  return axios.delete(url);
}

function updateProductAssignment(data) {
  let url = API.updateAssignedPermissions.toUrl();
  return axios.post(url, data);
}

function getAssignPermissions(id) {
  let url = API.assignProduct.toUrl({ id: id });
  return axios.get(url);
}

function getAllActiveProducts() {
  let url = API.getAllActiveProducts.toUrl();
  return axios.get(url);
}

function getProductHotline(productId) {
  let url = API.getProductHotline.toUrl({ id: productId });
  return axios.get(url);
}

function getAllCompanyProductsByCompanyId(id) {
  let url = API.getAllCompanyProductsByCompanyId.toUrl({ id });
  return axios.get(url);
}

function getAllProductHotlinesByCompany() {
  let url = API.getAllProductHotlinesByCompany.toUrl()
  return axios.get(url);
}

function addProductHotline(productHotlineObject) {
  let url = API.addProductHotline.toUrl()
  return axios.post(url, productHotlineObject)
}

function updateProductHotline(productHotlineObject) {
  let url = API.updateProductHotline.toUrl()
  return axios.post(url, productHotlineObject)
}

function deleteProductHotline(productId) {
  let url = API.deleteProductHotline.toUrl({ id: productId })
  return axios.delete(url)
}
