import {
  USER_PROFILE
} from "./types";
import axios from "axios";
import { store } from "../_helpers/store";
import { userService } from "../_services/user.service";
import { apiBaseUrl } from "../config.example";

export function profile() {
  const token = userService.getToken();
  const api = `/account/profile`;

  axios
    .get(apiBaseUrl + api, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      try {
        store.dispatch({
          type: USER_PROFILE,
          payload: res
        });
      } catch (err) {
        console.log("error", err);
      }
    });
}
