import "./App.scss";

import React, { Component } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { userService } from "./_services/user.service";
import { GlobalHistory } from "./_helpers/history";
import { Spinner } from "reactstrap";
import Imprint from "./website/imprint";
import Privacy from "./website/privacy";
import PrivateRoute from "./PrivateRoute";
import GPT from "./website/dashboard/gpt";
import Search from "./website/dashboard/search";
import Support from "./website/dashboard/support";
import Profile from "./website/dashboard/profile";
import Messages from "./website/dashboard/messages";
import Chatroom from "./website/dashboard/chatroom.jsx";
import DocRegister from "./views/Public/Login/docregister.jsx";
import searchDisplay from "./website/dashboard/search/searchDisplay";
import ContainingResult from "./website/dashboard/search/containing-results";
import ProductDetails from "./website/dashboard/product-details/ProductDetails";

const loading = () => <Spinner color="success" />;
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Public/Login"));
const Maintenance = React.lazy(() =>
  import("./views/Public/Login/Maintenance")
);
const Fgt = React.lazy(() => import("./views/Public/Login/Fgt"));
const Reset = React.lazy(() => import("./views/Public/Login/Reset"));

const Logout = React.lazy(() => import("./views/Public/Login/Logout"));
const VerifyEmail = React.lazy(() => import("./views/Public/Verify/VerifyEmail"));

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <GlobalHistory />
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) =>
                userService.isLoggedIn() ? (
                  <Redirect to="/" />
                ) : (
                  <Login {...props} />
                )
              }
            />
            <Route
              exact
              path="/logout"
              name="Logout Page"
              render={(props) => <Logout {...props} />}
            />
            <Route
              exact
              path="/forgottenpassword"
              name="Forgotten Password"
              render={(props) => <Fgt {...props} />}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              render={(props) => <Reset {...props} />}
            />
            <Route
              exact
              path="/verify-email"
              name="Verify Email"
              render={(props) => <VerifyEmail {...props} />}
            />
            <Route
              exact
              path="/dashboard/product/details"
              name="productDetails"
              render={(props) => <ProductDetails {...props} />}
            />
            <PrivateRoute
              path="/dashboard"
              name="Home"
              component={DefaultLayout}
            />
            <Route exact path="/profile" name="profile" component={Profile} />
            <Route
              exact
              path="/chatroom"
              name="chatroom"
              component={Chatroom}
            />
            <Route
              exact
              path="/messages"
              name="messages"
              component={Messages}
            />
            <Route exact path="/support" name="support" component={Support} />
            <Route exact path="/search" name="search" component={Search} />
            <Route exact path="/privacy" name="privacy" component={Privacy} />
            <Route exact path="/imprint" name="imprint" component={Imprint} />
            <Route exact path="/gpt" name="gpt" component={GPT} />
            <Route
              exact
              path="/resultLoader"
              name="resultLoader"
              render={(props) => <ContainingResult {...props} />}
            />
            <Route
              exact
              path="/docregister"
              name="docregister"
              component={DocRegister}
            />
            <Route
              exact
              path="/maintenance"
              name="maintenance"
              component={Maintenance}
            />
            <Route
              exact
              path="/searchDisplay"
              name="searchDisplay"
              component={searchDisplay}
            />
            <Redirect to="/dashboard" />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
